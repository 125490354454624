<template>
  <base-error>
    <template slot="heading">{{ title }}</template>
    <template slot="text">
      <span v-html="message"></span>
    </template>
  </base-error>
</template>

<script>
import BaseError from "@/pages/Errors/Base"

export default {
  name: "FourOhThree",

  components: {
    BaseError,
  },

  data() {
    return {
      title: "Forbidden",
      message: `Woah there! Looks like you do not have permission to view this page. You should go <router-link :to="{ name: 'home' }">back home</router-link>.`,
    }
  },

  created() {
    if (this.$route.query.link === null) {
      this.title = "Invalid Link"
      this.message = `The url used is either invalid or has expired.`
    }
  },
}
</script>
